/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BModal,
  BLink,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BSpinner,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required, forgotpasswordEmail } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import { NoSpace } from '@/libs/helpers'
import moment from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BModal,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      error: '',
      status: '',
      usernameEmail: '',
      emailverifikasi: '',
      showResendEmailVerification: false,
      dismissCountDown: 0,
      dismissSecs: 0,
      loading: false,
      custommessages: {
        required: 'Mohon masukan email.',
      },
      password: '',

      required,
      forgotpasswordEmail,
      captchaToken: '',

      NoSpace,
      countdownData: {
        date_time: moment(),
        time_now: moment().add(60, 'seconds'),
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    resendTextStyle() {
      if (this.dismissSecs > 0) {
        return {
          color: '#828282',
        }
      }
      return {
        color: '#F95031',
      }
    },
  },
  mounted() {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_RECAPTCHA_TOKEN}`
    document.head.appendChild(script)
  },
  methods: {
    handleClickResend() {
      if (this.dismissSecs === 0) {
        this.sendForgotPassord()
      }
    },
    countDownChanged() {
      if (this.dismissSecs > 0) {
        setTimeout(() => {
          this.dismissSecs -= 1
          this.countDownChanged()
        }, 1000)
      }
    },
    resendEmailVerification() {
      this.$http_komship
        .post('/v1/forgot-password', {
          email: this.usernameEmail,
        })
        .then(response => {
          if (response.data.code === 400) {
            this.error = response.data.message
            this.loading = false
            this.emailverifikasi = 'Belum ada akun yang menggunakan email ini.'
          } else {
            this.showResendEmailVerification = true
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleRedirectTodataforgot() {
      this.$router.push('login')
    },
    submit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.sendForgotPassord()
        }
      })
    },
    async sendForgotPassord() {
      this.loading = true
      this.error = ''
      this.emailverifikasi = ''

      try {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise.ready(async () => {
          // eslint-disable-next-line no-undef
          const token = await grecaptcha.enterprise.execute(
            process.env.VUE_APP_RECAPTCHA_TOKEN,
            { action: 'FORGOT_PASSWORD' },
          )
          this.captchaToken = token

          this.$http
            .post('/v1/partner/send-link-forgot-password', {
              email: this.usernameEmail,
              recaptcha_token: this.captchaToken,
            })
            .then(response => {
              if (response.data.code === 200 && response.data.data) {
                this.countdownData = {
                  date_time: moment(response.data.data.date_time),
                  time_now: moment(response.data.data.time_now),
                }

                const expiredCoundown = Math.abs(
                  this.countdownData.date_time.diff(
                    this.countdownData.time_now,
                    'seconds',
                  ),
                )
                this.dismissSecs = expiredCoundown
                this.showResendEmailVerification = true
                this.loading = false
                this.$nextTick(() => this.countDownChanged())
              } else {
                this.loading = false
              }
            })
            .catch(error => {
              if (
                error.response.data.message
              === 'Reset Password failed, invalid role id'
            && !(error.response.data.data && error.response.data.data.date_time)
              ) {
                this.error = error.response.data.message
                this.emailverifikasi = 'Maaf email kamu telah terdaftar di role lain pada Komerce. Silakan menggunakan email lain.'
              }

              if (
                error.response.data.message
              === 'Reset Password failed, please check your email, is it already registered?'
            && !(error.response.data.data && error.response.data.data.date_time)
              ) {
                this.error = error.response.data.message
                this.emailverifikasi = 'Belum ada akun yang menggunakan email ini.'
              }

              // TODO: Hanlde to countinue countdown when countdown is runnig
              if (
                error.response.data.data
            && error.response.data.data.date_time
            && error.response.data.data.time_now
              ) {
                this.countdownData = {
                  date_time: moment(error.response.data.data.date_time),
                  time_now: moment(error.response.data.data.time_now),
                }

                const expiredCoundown = Math.abs(
                  this.countdownData.date_time.diff(
                    this.countdownData.time_now,
                    'seconds',
                  ),
                )

                this.dismissSecs = expiredCoundown
                this.showResendEmailVerification = true
                this.loading = false
                this.$nextTick(() => this.countDownChanged())
              }
              this.loading = false
            })
        })
      } catch (error) {
        console.error('reCAPTCHA execution failed', error)
      }
    },
  },
}
