import { render, staticRenderFns } from "./ForgotPassword.html?vue&type=template&id=16a17b64&scoped=true&"
import script from "./ForgotPassword.js?vue&type=script&lang=js&"
export * from "./ForgotPassword.js?vue&type=script&lang=js&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ForgotPassword.scss?vue&type=style&index=1&id=16a17b64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a17b64",
  null
  
)

export default component.exports